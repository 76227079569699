(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsModalComponent', {
    template: require('ajs/modules/user/notifications/modal/notifications.html').default,
    controller: UserNotificationsModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function UserNotificationsModalController(
    $state,
    CourseRegistration,
    LearningObjectRegistration,
    LearningObjectRegistrationWorkflow,
    courseQuizTypes,
    siteSettingService,
    userEmailOptInService,
    currentUser,
    backUrlService,
    _,
  ) {
    var vm = this;
    vm.$onInit = onInit;
    vm.backUrlParam = backUrlService.external.getBackUrlParam();
    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.courseQuizTypes = courseQuizTypes;
    vm.profileIncomplete = null;
    vm.unpinnedNotifications = [];
    vm.user = currentUser.get();

    vm.tryGoToCourseDetails = tryGoToCourseDetails;
    vm.markAsViewed = markAsViewed;
    vm.launchAction = launchAction;
    vm.launchUrl = launchUrl;
    vm.showOptInModal = showOptInModal;

    function onInit() {
      vm.notifications = vm.resolve.notifications;
      vm.cancel = vm.modalInstance.dismiss;

      vm.profileIncomplete = _.find(vm.notifications, ['type', 'profileIncomplete']) || null;
      vm.upcomingEvents = _.filter(vm.notifications, { type: 'upcomingEvents' });
      vm.unpinnedNotifications = _.filter(vm.notifications, function (item) {
        return item.type !== 'profileIncomplete' && item.type !== 'upcomingEvents';
      });
    }

    function tryGoToCourseDetails(params) {
      if (!$state.includes('main.course', params)) {
        $state.go('main.course', params);
      }
    }

    function markAsViewed(notification) {
      vm.modalInstance.close(notification);
    }

    function launchAction(action, registrationId) {
      CourseRegistration.get({ registrationId: registrationId }, function (registration) {
        var workflow;

        vm.registration = LearningObjectRegistration.getFromReg(registration);

        workflow = new LearningObjectRegistrationWorkflow(vm.registration);

        if (workflow.hasAction(action)) {
          workflow.exec(
            action,
            {},
            {
              skipPrerequisites: true,
            },
          );
        } else {
          vm.tryGoToCourseDetails({ id: vm.registration.course.id });
        }
      });
    }

    function launchUrl(url, params, notification) {
      $state.go(url, params);
      vm.markAsViewed(notification);
    }

    function showOptInModal(notification) {
      userEmailOptInService.showOptInModal(currentUser.get().email).then(
        function () {
          markAsViewed(notification);
        },
        function () {
          vm.modalInstance.dismiss();
        },
      );
    }
  }
})();
