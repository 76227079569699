(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsBadge', {
    template: require('ajs/modules/user/notifications/notifications-badge.html').default,
    controller: UserNotificationsController,
    controllerAs: 'vm',
  });

  /* @ngInject*/
  function UserNotificationsController(
    userNotificationsService,
    $cookies,
    $uibModal,
    $scope,
    kmiUserNotificationsAlertWasShown,
    $transitions,
  ) {
    var vm = this;

    vm.showNotifications = userNotificationsService.showNotifications;
    vm.$onInit = onInit;

    function onInit() {
      vm.identifier = '';
      vm.notifications = [];

      activate();
    }

    function activate() {
      $scope.$on('event:notifications:updated', updateNotifications);
      var stateChangeCleanup = $transitions.onSuccess({}, function () {
        vm.stateChanged = true;
        stateChangeCleanup();
      });
      userNotificationsService.check();
    }

    function updateNotifications(event, data) {
      vm.identifier = data.identifier;
      vm.notifications = data.notifications;

      // Show notifications alert if it was not shown today for current user
      if (
        vm.notifications.length > 0 &&
        $cookies.get(kmiUserNotificationsAlertWasShown) !== data.identifier &&
        !vm.stateChanged
      ) {
        showAlert();
      }
    }

    function showAlert() {
      var modalInstance = $uibModal.open({
        component: 'userNotificationsAlertModalComponent',
        resolve: {
          notifications: function () {
            return vm.notifications;
          },
          identifier: function () {
            return vm.identifier;
          },
        },
      });

      modalInstance.result.then(function (showNotifications) {
        if (showNotifications) {
          vm.showNotifications();
        }
      });
    }
  }
})();
