(function () {
  angular.module('kmi.lms.user.notifications').component('userNotificationsAlertModalComponent', {
    template: require('ajs/modules/user/notifications/modal/notifications-alert.html').default,
    controller: UserNotificationsAlertModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function UserNotificationsAlertModalController($cookies, $timeout, kmiUserNotificationsAlertWasShown, _) {
    var vm = this;
    vm.$onInit = onInit;

    vm.notificationsCount = 0;
    vm.profileIncomplete = null;
    vm.showNotifications = showNotifications;

    function onInit() {
      vm.identifier = vm.resolve.identifier;
      vm.notifications = vm.resolve.notifications;

      vm.cancel = vm.modalInstance.dismiss;

      vm.notificationsCount = vm.notifications.length;
      vm.profileIncomplete = _.find(vm.notifications, ['type', 'profileIncomplete']) || null;

      setCookie();

      $timeout(function () {
        vm.modalInstance.close();
      }, 5e3);
    }

    function showNotifications() {
      vm.modalInstance.close(true);
    }

    function setCookie() {
      var today = new Date();
      var expires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

      // Add record to cookie about alert was shown today for current user
      $cookies.put(kmiUserNotificationsAlertWasShown, vm.identifier, { path: '/', expires: expires });
    }
  }
})();
